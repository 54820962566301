/* Start Global Rules */
* {
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
}
a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.container {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
/* Small */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/* Medium */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
/* End Global Rules */

/* Start Landing Page */
.landing-page header {
  min-height: 80px;
  display: flex;
}
@media (max-width: 768px) {
  .landing-page header {
    min-height: auto;
    display: initial;
  }
}
.landing-page header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



@media (max-width: 768px) {
  .landing-page header .container {
    flex-direction: column;
    justify-content: center;
  }
}
.landing-page header .logo {
  color: #5d5d5d;
  font-style: italic;
  text-transform: uppercase;
  font-size: 20px;
  
  max-width: 75%;
  overflow-x: hidden;
}


@media (max-width: 320px) {
  .landing-page header .logo {
    font-size: 10px;
  }
}


@media (max-width: 768px) {
  .landing-page header .logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.landing-page header .links {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .landing-page header .links {
    display: block;
    text-align: center;
    gap: 10px;
  }
}
.landing-page header .links li {
  margin-left: 30px;
  color: #5d5d5d;
  cursor: pointer;
  transition: .3s;
}
@media (max-width: 768px) {
  .landing-page header .links li {
    padding: 15px 0 0 0;
    margin-left: auto;
  }
}
.landing-page header .links li:last-child a {
  border-radius: 20px;
  padding: 5px 20px;
  // color: #6c63ff;
  font-weight: bold;
  border: 2px solid rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}
.landing-page header .links li:not(:last-child):hover a {
  color: #6c63ff;
}

a.telephone {
  border-radius: 20px;
  padding: 5px 20px;
  color: #6c63ff;
  font-weight: bold;
  border: 2px dashed #6c63ff;
}

a.telephone:hover {
  border: 2px solid #6c63ff;
}

.landing-page .content .container {
  display: flex;
  display: -webkit-flex; /* NEW */
  align-items: center;
  justify-content: space-between;
  gap: 140px;
  min-height: calc(100vh - 80px);
  
}
@media (max-width: 768px) {
  .landing-page .content .container {
    gap: 0;
    min-height: calc(100vh - 101px);
    justify-content: center;
    // flex-direction: column-reverse;
    flex-direction: column;
    -webkit-flex-direction: column;
    
  }
}

  .landing-page .content .container {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; /* NEW */
  }
  
@media (min-width: 768px) {
  .landing-page .content .container {
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap; /* NEW */
  }
}


@media (max-width: 768px) {
  .landing-page .content .info {
    text-align: center;
    margin-bottom: 15px 
  }
  
.landing-page .content .info {
  flex: 1 0 auto;
}

.landing-page .content .image {
  flex: 1 0 auto;
  // display: none;
}
}

.landing-page .content .info h1, 
.landing-page .content .info h2 {
  color: #5d5d5d;
}

.landing-page .content .info h1 {
  font-size: 33px;
}

.landing-page .content .info h2 {
  font-size: 22px;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .landing-page .content .info h1,
  .landing-page .content .info h2 {
    font-weight: 600;
    padding-top: 25px;
  }
  
  .landing-page .content .info h1 {
    font-size: 26px;
  }
}


.landing-page .content .info p li {
  margin: 0;
  line-height: 1.6;
  font-size: 15px;
  color: #5d5d5d;
}
.landing-page .content .info button {
  border: 0;
  border-radius: 20px;
  padding: 12px 30px;
  margin-top: 30px;
  cursor: pointer;
  color: #FFF;
  background-color: #6c63ff;
  font-weight: bold;
}

// .landing-page .content .info button {
//   border: 4px sold black;
//   border-radius: 12px;
//   padding: 12px 30px;
//   margin-top: 30px;
//   cursor: pointer;
//   font-weight: bold;
//   background-color: white;
// }

.landing-page .content .image img {
  max-width: 100%;
}

@media (max-width: 768px) {
.landing-page .content .image img {
  max-width: 100%;
  // max-height: 120px;
}
}

/* End Landing Page */

footer img.logo {
  height: 2em;
}

img.katalog_pdf {
  height: 160px;
}


.highlight{
  border: 1px solid #aaa;
  // border: 2px solid #6c63ff;
  border-radius: 10px;
  padding: 15px;
  // background: linear-gradient(326deg, rgba(108,99,255,0.8) 0%, rgba(0,159,231,0.3) 15%, rgba(0,222,223,0.3) 50%, rgba(108,99,255,0.8) 100%);
}
