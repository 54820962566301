// Bootstrap Overrides
@import "./base/bootstrap_overrides";

// Bootstrap
@import "./variables.scss";
@import "./vendor/bootstrap";


$bootstrap-icons-font-src: url("../../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"), url("../../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";

// @import "./jelly-card.scss";
// @import "./testimonial.scss";
@import "./landing-page.scss";

